.slot {
    background-color: white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 4px;
    color: black;
}

.slot p {
    text-overflow: ellipsis;
    text-align: center;
    user-select: none;
    font-size: 2vmax;
    hyphens: auto;
}

@media (min-width: 1200px) {
    .slot p {
        font-size: 1.4em;
    }
}

@media (pointer: fine) {
    .slot:hover {
        background-color: ivory;
    }
}

.slot:hover {
    cursor: pointer;
}

.slot.guessed {
    background-color: gold;
}

.slot.win {
    background-color: mediumseagreen;
}
